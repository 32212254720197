<template>
    <div class="p-info-content">
        <div class="section-title-wrap mb-0 d-flex justify-content-between align-items-start">
            <div class="section-title margin-deliver">{{ $t("basic information")}}</div>
            <router-link class="edit-button" :to="{ name: 'candidate.basic-info.edit'}">
                <span class="edit-icon"><i class="eicon e-pencil"></i></span>
                <span>{{ $t("change information")}}</span>
            </router-link>
        </div>
        <profile-loader v-if="isContentLoading"></profile-loader>
        <div class="form-box basic-info-box" v-else>
            <div class="basic-info">
                <div class="row has-col-gap">
                    <div class="col-md-6">
                        <div class="p-info-card">
                            <div class="p-info-icon">
                                <i class="eicon e-user"></i>
                            </div>
                            <div class="p-info-text">
                                <span>{{ $t("First name") }}</span>
                                <p>{{ candidate.first_name }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="p-info-card">
                            <div class="p-info-icon">
                                <i class="eicon e-user"></i>
                            </div>
                            <div class="p-info-text">
                                <span>{{ $t("Last name") }}</span>
                                <p>{{ candidate.last_name }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="p-info-card">
                            <div class="p-info-icon">
                                <i class="eicon e-mail"></i>
                            </div>
                            <div class="p-info-text">
                                <span>{{ $t("Email") }}</span>
                                <p>{{ candidate.email }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="p-info-card">
                            <div class="p-info-icon">
                                <i class="eicon e-phone"></i>
                            </div>
                            <div class="p-info-text">
                                <span class="text-capitalize">{{ $t("phone number") }}</span>
                                <p>{{ candidate.mobile_number }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="p-info-card">
                            <div class="p-info-icon">
                                <i class="eicon e-flags"></i>
                            </div>
                            <div class="p-info-text">
                                <span>{{ $t("Nationality") }}</span>
                                <p>{{ candidate.nationality }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="p-info-card">
                            <div class="p-info-icon">
                                <i class="eicon e-pass"></i>
                            </div>
                            <div class="p-info-text">
                                <span>{{ $t("National ID") }}</span>
                                <p>{{ candidate.identity_number }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="p-info-card">
                            <div class="p-info-icon">
                                <i class="eicon e-dob"></i>
                            </div>
                            <div class="p-info-text">
                                <span>{{ $t("Date of Birth") }}</span>
                                <p>{{ additionalInfo.dob }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="p-info-card">
                            <div class="p-info-icon">
                                <i class="eicon e-gender"></i>
                            </div>
                            <div class="p-info-text">
                                <span>{{ $t("Gender") }}</span>
                                <p>{{ $t(additionalInfo.gender) }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="p-info-card">
                            <div class="p-info-icon">
                                <i class="eicon e-clock"></i>
                            </div>
                            <div class="p-info-text">
                                <span>{{ $t("Time zone") }}</span>
                                <p>{{ candidate.time_zone }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="p-info-card">
                            <div class="p-info-icon">
                                <i class="eicon e-flags"></i>
                            </div>
                            <div class="p-info-text">
                                <span>{{ $t("Current salary") }}</span>
                                <p>{{ candidate.current_salary }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="p-info-card">
                            <div class="p-info-icon">
                                <i class="eicon e-flags"></i>
                            </div>
                            <div class="p-info-text">
                                <span>{{ $t("Expected salary") }}</span>
                                <p>{{ candidate.expected_salary }}</p>
                            </div>
                        </div>
                    </div>


                    <div class="col-md-6">
                        <div class="p-info-card">
                            <div class="p-info-icon">
                                <i class="eicon e-linkedin"></i>
                            </div>
                            <div class="p-info-text">
                                <p class="text-capitalize">{{ $t("LinkedIn profile link") }}</p>
                                <a target="_blank" :href="additionalInfo.linkedin">{{ additionalInfo.linkedin | shortLink }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="p-info-card">
                            <div class="p-info-icon">
                                <i class="eicon e-twitter"></i>
                            </div>
                            <div class="p-info-text">
                                <p class="text-capitalize">{{ $t("Twitter profile link") }}</p>
                                <a target="_blank" :href="additionalInfo.twitter">{{ additionalInfo.twitter | shortLink }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="p-info-card">
                            <div class="p-info-icon">
                                <i class="eicon e-facebook"></i>
                            </div>
                            <div class="p-info-text">
                                <p class="text-capitalize">{{ $t("Facebook profile link") }}</p>
                                <a target="_blank" :href="additionalInfo.facebook">{{ additionalInfo.facebook | shortLink }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="p-info-card">
                            <div class="p-info-icon">
                                <i class="eicon e-document"></i>
                            </div>
                            <div class="p-info-text">
                                <p class="text-capitalize">{{ $t("Intro Video Link") }}</p>
                                <a target="_blank" :href="candidate.intro_video_link">{{ candidate.intro_video_link |shortLink }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="image-selector">
                <div class="img-box">
                    <img :src="additionalInfo.photo" :alt="candidate.first_name"/>

                    <a href="#" class="remove-button"
                       v-if="hasPhoto"
                       @click.prevent="removePhoto">
                        <i class="eicon e-delete"></i>
                    </a>
                </div>
                <label class="file-browse-button">
                    <span class="text-capitalize">{{ $t("change") }}</span>
                    <input
                            id="profile_image"
                            ref="profile_image"
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            @change="setCroppieConfig(175, 175, 175, 175); croppie('profile_image', $event)"/>
                </label>
            </div>
        </div>
        <div v-if="showCropperModel" class="modal vue-cropper show" id="vue-cropper-modal" style="display: block">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content" v-on-click-away="closeCropperModal">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t("Resize Image") }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" @click="closeCropperModal">&times;</span>
                        </button>
                    </div>
                    <div id="image-crop-wrapper" class="modal-body croppie-container ready">
                        <vue-croppie
                                ref="croppieRef"
                                :enableOrientation="true"
                                :enableResize="false"
                                :boundary="croppieShowOption.boundary"
                                :viewport="croppieShowOption.viewport">
                        </vue-croppie>
                    </div>

                    <div class="modal-footer flex justify-content-between">
                        <button type="button" class="button warning-button" @click="closeCropperModal">{{ $t(`Close`) }}
                        </button>
                        <button type="button" @click.prevent="cropImage()" class="button success-button" :class="{'disabled' : uploadingImage}">
                            <span v-if="uploadingImage">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="mr-2" style="margin: auto; shape-rendering: auto;" width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                    <circle cx="50" cy="50" r="24" stroke-width="8" stroke="#fff" stroke-dasharray="37.69911184307752 37.69911184307752" fill="none" stroke-linecap="round" transform="rotate(300.791 50 50)">
                                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.01010101010101s" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
                                    </circle>
                                </svg>
                                {{ $t("please wait") }}
                            </span>
                            <template v-else>{{ $t(`Save`) }}</template>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showCropperModel" class="modal-backdrop fade show"></div>
    </div>
</template>

<script>
import Vue from "vue";
const ProfileLoader = () => import("../../../../components/_loaders/ProfileLoader");

import {getCandidateInfo as loadCandidateInfo} from "../../../../app/api/CommonRequest";
import {mapActions} from "vuex";
import {UPDATE_USER_DATA} from "../../../../constants/action-type";
import client from "../../../../app/api/Client";
import VueCroppie from "vue-croppie";
import 'croppie/croppie.css';

Vue.use(VueCroppie);

export default {
    name: "List",
    components: {
        ProfileLoader,
    },
    data() {
        return {
            candidate: {
                first_name: null,
                email: null,
                nationality: null,
                dob: null,
                last_name: null,
                country_id: null,
                mobile_number: null,
                identity_number: null,
                gender: null,
                social_link: [],
                intro_video_link: null,
                current_salary: null,
                expected_salary: null,
                time_zone: null
            },
            additionalInfo: {
                photo: "/assets/images/placeholder/profile_150X150.jpeg",
            },
            showCropperModel: false,
            tempFile: null,
            uploadingImage: false,
            isContentLoading: false,
            croppieUploadOption: {
                enableExif: true,
                quality: 1,
                // format: 'jpeg',
                size: {
                    width: null,
                    height: null
                },
            },
            croppieShowOption: {
                boundary: {
                    width: null,
                    height: null,
                },
                viewport: {
                    width: null,
                    height: null,
                }
            },
            isLoading: false,
            hasPhoto: false,
        }
    },
    filters: {
        shortLink: function (value) {
            if (!value) return '';
            if (value === undefined) return '';
            if (value.length > 26) {
                return value.slice(0, 26) + '...';
            }
            return value;
        }
    },
    methods: {
        ...mapActions([UPDATE_USER_DATA]),
        async getCandidateInfo() {
            this.isContentLoading = true;
            try {
                let {data} = await loadCandidateInfo();
                this.candidate = data.candidate;
                this.additionalInfo = data.additional;
                this.hasPhoto = data.additional.hasPhoto;
            } catch (e) {
            }
            this.isContentLoading = false;
        },
        async removePhoto() {
            try {
                let confirm = await this.$dialog.confirm({
                    title: this.$t(`Confirmation`),
                    body: this.$t(`Are you sure you want to remove profile photo?`)
                });

                if (!confirm) return false;

                let {data: {data: user, message}} = await client().post('/candidate/photo', {
                    _method: 'DELETE'
                });

                this.additionalInfo.photo = user.profile_image;
                this.hasPhoto = false;
                this[UPDATE_USER_DATA](user);
                this.$toast.success(message);
            } catch (e) {
            }
        },

        croppie(target, e) {

            this.showCropperModal();

            this.target = target;

            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                this.closeCropperModal();
                return;
            }


            this.tempfile = files[0];
            let allowedFileExists = ['png', 'jpg', 'jpeg'];

            if (!allowedFileExists.includes(this.tempfile.name.split('.').pop().toLowerCase())) {
                this.closeCropperModal();
                this.$toast.error(this.$t(`Invalid image format.`));
                return;
            }

            var reader = new FileReader();

            reader.onload = e => {
                this.$refs.croppieRef.bind({
                    url: e.target.result
                });
            };

            reader.readAsDataURL(this.tempfile);
        },

        cropImage() {

            let croppedImage = this.$refs.croppieRef;

            croppedImage.result(this.croppieUploadOption, base64 => {

                fetch(base64).then(e => e.blob()).then((blob) => {

                    const formData = new FormData();

                    formData.append('photo', blob, this.tempfile.name);

                    this.uploadingImage = true;

                    client()
                        .post('/candidate/photo', formData)
                        .then(({data}) => {

                            this.additionalInfo.photo = base64;

                            this.uploadingImage = false;

                            this.hasPhoto = true;

                            this[UPDATE_USER_DATA](data.data);

                            this.closeCropperModal();

                            return this.$toast.success(this.$t(data.message));

                        }).catch(error => {

                        this.showErrorText(error)
                    }).finally(() => {
                        this.uploadingImage = false;
                    });
                })
            });
        },
        showErrorText(error) {
            if (error.response !== undefined && [422, 400].includes(error.response.status)) {/* validation error */
                if (typeof error.response.data.message == 'string') {
                    return this.$toast.error(error.response.data.message ?? '');/* System error */
                }
                this.errors = error.response.data.message;
                return this.$toast.error('Oops! Please check your input');
            } else {
                // this.$toast.error('Oops! Somethings was wrong');
            }
        },

        setCroppieConfig(vWidth, vHeight, width, height) {

            this.croppieUploadOption.size.width = width;
            this.croppieUploadOption.size.height = height;
            this.croppieShowOption.viewport.width = vWidth;
            this.croppieShowOption.viewport.height = vHeight;

            this.croppieShowOption.boundary.width = vWidth + 100;
            this.croppieShowOption.boundary.height = vHeight + 100;

        },

        showCropperModal() {
            this.showCropperModel = true;
            const el = document.body;
            el.classList.add('modal-open');
        },

        closeCropperModal() {
            document.getElementById('profile_image').value= null;
            this.showCropperModel = false;
            this.fade = false;
            const el = document.body;
            el.classList.remove('modal-open');
        },

    },
    async mounted() {
        await this.getCandidateInfo();

    },
}
</script>

<style scoped>
.img-box {
    position: relative;
}

.img-box a.remove-button {
    position: absolute;
    right: 4px;
    color: rgba(245, 245, 245);
    background: #ff5f74;
    top: 4px;
    font-size: .7em;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    border-radius: 2px;
}
</style>
